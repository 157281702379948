import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../index'
import { Employee } from '../typing/Types';
import { EmployeesApi } from '../api/Employees';
import { HierarchyApi } from '../api/Hierarchy';

export interface EmployeeState {
  employees: Array<Employee> | null;
  loading: boolean;
  errors: string;
}

const initialState: EmployeeState = {
  employees: null,
  loading: false,
  errors: '',
}

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setEmployees: (state, { payload }: PayloadAction<Employee[]>) => {
      state.employees = payload
    },

    updateEmployee: (state, { payload }: PayloadAction<Employee>) => {
      if (state.employees) {
        const index = state.employees.findIndex((item) => payload.id === item.id);
        if (index > -1) {
          state.employees.splice(index, 1, payload);
        }
      }
  },
  },
})

export const getEmployees = (): AppThunk => {
    return async dispatch => {
      dispatch(setLoading(true))
      try {
        const data = await EmployeesApi.get({ onboardingStatus: 'working' });
        dispatch(setEmployees(data))
        dispatch(setLoading(false))
      } catch (error) {
        if (typeof error === 'string'){
            dispatch(setErrors(error as string))
        } else {
            console.log(error);
            dispatch(setErrors('Unknown error'));
        }
        dispatch(setLoading(false))
      }
    }
  }

  export const saveEmployee = (employee: Employee): AppThunk => {
    return async dispatch => {
      dispatch(setLoading(true))
      try {
        const savedEmployee = await EmployeesApi.save(employee);
        const employeeUpdated = {
          ...employee,
          ...savedEmployee
        }
        dispatch(updateEmployee(employeeUpdated));
        dispatch(setLoading(false));
      } catch (error) {
        if (typeof error === 'string'){
            dispatch(setErrors(error as string))
        } else {
            console.log(error);
            dispatch(setErrors('Unknown error'));
        }
        dispatch(setLoading(false))
      }
    }
  }

export const {
  setLoading,
  setErrors,
  setEmployees,
  updateEmployee
} = employeeSlice.actions

export default employeeSlice.reducer

export const employeeSelector = (state: { employee: EmployeeState }) => state.employee;
