import React from 'react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgChartsReact } from 'ag-charts-react';
import { AgGridReact } from 'ag-grid-react';


const PieChart = ({ fieldName, data }) => {
    return (
        <div>
            <div
                style={{
                height: '500px',
                margin: '10px 0'
                }}> 
                <AgChartsReact options={{
                    data: data,
                    title: {
                    text: fieldName.charAt(0).toUpperCase() + fieldName.slice(1),
                    },
                    series: [
                    {
                        type: "pie",
                        angleKey: "value",
                        legendItemKey: fieldName,
                        calloutLabelKey: fieldName,
                        calloutLabel: {
                            avoidCollisions: true,
                            enabled: true,
                        },
                        sectorLabelKey: 'value',
                        sectorLabel: {
                        color: 'white',
                        fontWeight: 'bold',
                        },
                    },
                    ],
                }} />
            </div>
            <div className="ag-theme-quartz">
                <AgGridReact
                    domLayout='autoHeight'
                    rowData={data}
                    columnDefs={[
                    { field: fieldName, flex: 2 },
                    { field: "value", flex: 1 }
                    ]}
                />
            </div>
        </div>
    );
};

export default PieChart;

