
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../index'
import { RolesApi } from '../api/Roles';

export interface RolesState {
  roles: Array<any>;
  loading: boolean;
  errors: string;
}

const initialState: RolesState = {
  roles: [],
  loading: false,
  errors: '',
}

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setRoles: (state, { payload }: PayloadAction<any[]>) => {
      state.roles = payload
    },
  },
})

export const getroles = (): AppThunk => {
    return async dispatch => {
      dispatch(setLoading(true))
      try {
        const roles = await RolesApi.get();
  
        dispatch(setRoles(roles))
        dispatch(setLoading(false))
      } catch (error) {
        if (typeof error === 'string'){
            dispatch(setErrors(error as string))
        } else {
            console.log(error);
            dispatch(setErrors('Unknown error'));
        }
        dispatch(setLoading(false))
      }
    }
  }

export const { setLoading, setErrors, setRoles } = roleSlice.actions

export default roleSlice.reducer

export const roleSelector = (state: { role: RolesState }) => state.role;
