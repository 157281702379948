import React from 'react';
import { Layout } from 'antd';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

const { Content } = Layout;



const ErrorPage: React.FC = () => {
  const error = useRouteError();
  let message: string;
  if (isRouteErrorResponse(error)) {
      // error is type `ErrorResponse`
      message = error.data?.message || error.statusText;
    } else if (error instanceof Error) {
      message = error.message as string;
    } else if (typeof error === 'string') {
      message = error;
    } else {
      console.error(error);
      message = 'Unknown error';
    }
  return (
      <Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{message}</i>
        </p>
      </Content>
  );
};

export default ErrorPage;