
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../index'
import { LocationsApi } from '../api/Locations';

export interface LocationsState {
  locations: Array<any>;
  loading: boolean;
  errors: string;
}

const initialState: LocationsState = {
  locations: [],
  loading: false,
  errors: '',
}

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setLocations: (state, { payload }: PayloadAction<any[]>) => {
      state.locations = payload
    },
  },
})

export const getlocations = (): AppThunk => {
    return async dispatch => {
      dispatch(setLoading(true))
      try {
        const locations = await LocationsApi.get();
  
        dispatch(setLocations(locations))
        dispatch(setLoading(false))
      } catch (error) {
        if (typeof error === 'string'){
            dispatch(setErrors(error as string))
        } else {
            console.log(error);
            dispatch(setErrors('Unknown error'));
        }
        dispatch(setLoading(false))
      }
    }
  }

export const { setLoading, setErrors, setLocations } = locationSlice.actions

export default locationSlice.reducer

export const locationSelector = (state: { location: LocationsState }) => state.location;
