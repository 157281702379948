import { Employee } from "../typing/Employee";
import { teamRegApi, teamRegEndpoints } from "./ApiConfig";
import { snakeToCamel } from "./helpers";

export class EmployeesApi {
    static async get (filter: any): Promise<Employee[]> {
        let url = `${teamRegApi}${teamRegEndpoints.employees}`;
        if (filter.onboardingStatus) {
            url += `?onboarding_status=${filter.onboardingStatus}`;
        }
        const res = await fetch(url, {
            method: 'GET',
            credentials: 'include',
          });
        const raw = await res.json();
        return snakeToCamel(raw);
    }
    
    static async save (employee: any): Promise<Employee> {
        try {
            const data = this.serializeEmployeeRecord(employee);
            const request = new Request(`${teamRegApi}${teamRegEndpoints.employees}/${employee.id}`, {
                method: 'PUT',
                credentials: 'include',
                headers: new Headers({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            })
        
            const res = await fetch(request);
            const raw = await res.json();
    
            return this.normalizeEmployeeRecord(raw);
        } catch (e) {
            console.log(e);
            throw new Error('Error while saving employees');
        }
    }

    private static normalizeEmployeeRecord (data: any) : any {
        const employee: any = {};
        for (let [key, value] of Object.entries(employeeKeyMap)) {
            employee[value] = data[key];
        }
        return employee;
    }

    private static serializeEmployeeRecord (employee: any) : Object {
        let employeeData: any = {};
        for (let [key, value] of Object.entries(employeeKeyMap)) {
            employeeData[key] = employee[value];
        }
        return employeeData;
    }
}



const employeeKeyMap = {
    'id': 'id',
    'LDAP': 'LDAP',
    'billability': 'billability',
    'client_email': 'clientEmail',
    'first_name': 'firstName',
    'last_name': 'lastName',
    'gd_email': 'gdEmail',
    'location_id': 'locationId',
    'offboarded_date': 'offboardedDate',
    'onboarded_date': 'onboardedDate',
    'onboarding_id': 'onboardingId',
    'onboarding_status': 'onboardingStatus',
    'phone': 'phone',
    'project_id': 'projectId',
    'role_id': 'roleId',
    'squad': 'squad',
    'contract_type': 'contractType'
  }