import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import LoginSlice, { LoginState } from './features/LoginSlice';
import EmployeeSlice from './features/EmployeeSlice';
import StatsSlice from './features/StatsSlice';
import ProjectSlice from './features/ProjectsSlice';
import RolesSlice from './features/RolesSlice';
import LocationSlice from './features/LocationSlice';
import ManagerSlice from './features/ManagerSlice';
import DepartmentSlice from './features/DepartmentSlice';

// The AppThunk type will help us in writing type definitions for thunk actions
export type AppThunk = ThunkAction<void, LoginState, unknown, Action<string>>;

const store = configureStore({
  reducer: {
    login: LoginSlice,
    employee: EmployeeSlice,
    stats: StatsSlice,
    project: ProjectSlice,
    role: RolesSlice,
    location: LocationSlice,
    manager: ManagerSlice,
    department: DepartmentSlice,
    // middleware: ['array of middlewares'],
    // devTools: process.env.NODE_ENV !== "development" ? false : true,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
