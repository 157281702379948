import React, { useState } from 'react';
import { Typography, Layout, Badge, theme } from 'antd';
import ManagerMenu from '../components/ManagersMenu';
import EmployeesTable from '../components/ProjectEmployeesTable';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { employeeSelector, getEmployees } from '../features/EmployeeSlice';
import { departmentSelector, getDepartments } from '../features/DepartmentSlice';
import { getProjects, projectSelector } from '../features/ProjectsSlice';
import { getManagers, managerSelector } from '../features/ManagerSlice';
import { locationSelector, getlocations } from '../features/LocationSlice';
import { roleSelector, getroles } from '../features/RolesSlice';

const { Content, Sider } = Layout;
const { Title } = Typography;

function getProjectEmployeesDict (employees) {
    return employees.reduce((dict, e) => {
        if (dict[e.projectId]) {
            dict[e.projectId].push(e);
        } else {
            dict[e.projectId] = [e];
        }
        return dict;
    }, {})
}

const EmployeesRoute = () => {
    const {
        token: { colorBgContainer },
      } = theme.useToken();
    const dispatch = useDispatch();
    const [departmentId, setDepartment] = useState(0);
    const { employees, loading } = useSelector(employeeSelector);
    const { locations } = useSelector(locationSelector);
    const { roles } = useSelector(roleSelector);
    const { departments } = useSelector(departmentSelector);
    const { projects } = useSelector(projectSelector);
    const { managers } = useSelector(managerSelector);
    if (!employees && !loading) {
        dispatch(getEmployees());
        dispatch(getlocations());
        dispatch(getroles());
        dispatch(getDepartments());
        dispatch(getProjects());
        dispatch(getManagers());
    }
    const employeesDict = employees ? getProjectEmployeesDict(employees) : {};
    const department = departments.find(d => d.id === departmentId);

    let projectsToDisplay = [];
    if (departmentId === 0) {
        projectsToDisplay = projects.toSorted((a, b) => a.departmentId - b.departmentId);
    } else {
        projectsToDisplay = projects.filter(p => p.departmentId === department.id)
    }
    const titles = {
        locations: locations.map(l => l.title),
        projects: projects.map(p => p.title),
        roles: roles.map(r => r.title)
    }
    return (
        <Layout>
            <Sider
             width={350}
             style={{ 
                height: '100%',
                background: colorBgContainer
             }}
             collapsible={true}
             >
                <ManagerMenu
                    managers={ managers }
                    departments={ departments }
                    setDepartment={setDepartment}
                />
            </Sider>
            <Content style={{
                margin: 20,
            }}>
                {department && <Title level={2}>{department.title} <Badge count={department.headcount} /></Title>}
                {projectsToDisplay.map(p => (<div className="ag-theme-quartz">
                            <EmployeesTable
                                key={p.id}
                                header={p.title}
                                employees={employeesDict[p.id] || []}
                                locations={locations}
                                projects={projects}
                                roles={roles}
                                titles={titles} />
                        </div>))}
            </Content>
        </Layout>
    );
};

export default EmployeesRoute;