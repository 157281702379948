import React, { useEffect } from 'react';
import './Root.css';
import { 
  HomeOutlined,
  TeamOutlined,
  ReconciliationOutlined,
  AreaChartOutlined,
  UserAddOutlined,
 } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { ConfigProvider, Layout, Menu, theme } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';

const { Header, Content } = Layout;

export const headerMenuItems: MenuProps['items'] = [
  {
    key: '/',
    label: 'Home',
    icon: React.createElement(HomeOutlined),
  },
  {
    key: '/employees',
    label: 'Employees',
    icon: React.createElement(TeamOutlined),
  },
  {
    key: '/projects',
    label: 'Projects',
    icon: React.createElement(ReconciliationOutlined),
  },
  {
    key: '/dashboards',
    label: 'Dashboards',
    icon: React.createElement(AreaChartOutlined),
  }];

const Root: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            rowExpandedBg: colorBgContainer
          }
        },
      }}
    >
      <Layout style={{ minHeight: '100vh', overflow: 'auto' }}>
        <Header style={{ display: 'flex', alignItems: 'center' }}>
          <div className="demo-logo" />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['/employees']}
            items={headerMenuItems}
            style={{ flex: 1, minWidth: 0 }}
            onClick={({ key }) => {
              if(key) {
                navigate(key);
              }
            }}
          />
        </Header>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default Root;