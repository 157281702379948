import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../index'
import { StatsApi } from '../api/Stats';

export interface StatsState {
  manager: Array<any>;
  country: Array<any>;
  billability: Array<any>;
  loading: boolean;
  errors: string;
}

const initialState: StatsState = {
  manager: [],
  country: [],
  billability: [],
  loading: false,
  errors: ''
}

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setStats: (state, { payload }: PayloadAction<{
      managersStats: Array<any>,
      countriesStats: Array<any>,
      billabilityStats: Array<any>,
    }>) => {
      state.manager = payload.managersStats
      state.country = payload.countriesStats
      state.billability = payload.billabilityStats
    },

    setManagersStats: (state, { payload }: PayloadAction<any[]>) => {
      state.manager = payload
    },

    setCountriesStats: (state, { payload }: PayloadAction<any[]>) => {
      state.country = payload
    },

    setBillabilityStats: (state, { payload }: PayloadAction<any[]>) => {
      state.billability = payload
    },
  },
})

export const getStats = (): AppThunk => {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const [ managersStats, countriesStats, billabilityStats] = await Promise.all([
        StatsApi.getManagersStats(),
        StatsApi.getCountriesStats(),
        StatsApi.getBillabilityStats(),
      ])
      dispatch(setManagersStats(managersStats))
      dispatch(setCountriesStats(countriesStats))
      dispatch(setBillabilityStats(billabilityStats))
      dispatch(setLoading(false))
    } catch (error) {
      if (typeof error === 'string'){
          dispatch(setErrors(error as string))
      } else {
          console.log(error);
          dispatch(setErrors('Unknown error'));
      }
      dispatch(setLoading(false))
    }
  }
}

export const { setLoading, setErrors, setStats, setManagersStats, setCountriesStats, setBillabilityStats } = statsSlice.actions

export default statsSlice.reducer

export const statsSelector = (state: { stats: StatsState }) => state.stats;
