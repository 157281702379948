import React from 'react';
import { Layout, theme } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getStats, statsSelector } from '../features/StatsSlice';
import PieChart from '../components/PieChart';

const { Content, Sider } = Layout;

const Dashboards = () => {
  const dispatch = useDispatch();
    const {
        token: { colorBgContainer },
      } = theme.useToken();

    const { manager, country, billability, loading, errors } = useSelector(statsSelector);
    if (!loading && manager.length === 0 && country.length === 0 && billability.length === 0) {
      dispatch(getStats());
    }

    return (
        <Layout style={{
          height: '90s%'
      }}>
            <Content style={{
                padding: 20,
                height: '100%'
            }}>
                <h1>Dashboards</h1>
                {loading && <h1>Data is loading...</h1>}
                {errors && errors.length > 0 && errors}
                {!errors && !loading && 
                  <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around',
                    gap: '20px',
                  }}>
                    <PieChart fieldName={'manager'} data={manager} />
                    <PieChart fieldName={'billability'} data={billability} />
                    <PieChart fieldName={'country'} data={country} />
                  </div>
                  }
            </Content>
        </Layout>
    );
};

export default Dashboards;