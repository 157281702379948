import React, { useEffect } from 'react';
import { Alert, Button, Form, Input, Layout, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LoginState, login, loginSelector } from '../features/LoginSlice';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};


function Login() {
  const dispatch = useDispatch() as ThunkDispatch<any, Object, Action>;
  const { user, loading, errors } = useSelector(loginSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  const onFinish = (values: FieldType) => {
    if (values.username && values.password) {
      dispatch(login(values.username, values.password));
    }
  }

  return (
      <Layout style={{
          height: '100vh',
        }}>
        <Content style={{
          display: 'flex',
        }}>
          
          <Form
          name="basic"
          labelCol={{ span: 8 }}
          style={{ minWidth: 400, maxWidth: 600, margin: 'auto' }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          {errors.length > 0 && <Alert message={errors[0]} type="error" showIcon />}
          <Form.Item<FieldType>
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          </Space>
        </Form>
        
      </Content>
    </Layout>
  );
}

export default Login;