import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate, useNavigate, Outlet } from 'react-router-dom';
import Root from './routes/Root';
import ErrorPage from './routes/Error';
import Login from './routes/Login';
import Employees from './routes/Employees';
import Dashboards from './routes/Dashboards';
import { useDispatch, useSelector } from 'react-redux';
import { loginSelector, setUser, setErrors } from './features/LoginSlice';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { LoginApi } from './api';
import { UnauthorizedError } from './api/Login';

function RequireAuth({ children }: {children: any}) {
  const { isAuth } = useSelector(loginSelector);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuth) {
      navigate('/login');
    }
  }, [isAuth, navigate]);

  return children ? children : null;
}

function App() {
  const dispatch = useDispatch() as ThunkDispatch<any, Object, Action>;
  const { user, isAuth, loading, errors } = useSelector(loginSelector);

  React.useEffect(() => {
    const fetchData = async () => {
      if (!isAuth && !loading) {
        try {
          const user = await LoginApi.getCurrentUser();
          dispatch(setUser(user));
        } catch (e) {
          if (e instanceof UnauthorizedError) {
            dispatch(setErrors(['Login error']))
          }
        }
      }
    };
    fetchData();
  }, [isAuth, loading, dispatch]);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
            <Route index element={<Navigate replace to="/employees" />} />
            <Route path="login" element={<Login />} />
            <Route path="employees" element={<RequireAuth><Employees /></RequireAuth>} />
            <Route path="projects" element={<RequireAuth><h1>Not implemented</h1></RequireAuth>} />
            <Route path="dashboards" element={<RequireAuth><Dashboards /></RequireAuth>} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
