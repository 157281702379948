import React, { Dispatch, SetStateAction, useState } from 'react';
import {  Menu, MenuProps } from 'antd';
import { Manager } from '../typing/Manager';
import { AuditOutlined, UserOutlined, ReconciliationOutlined } from '@ant-design/icons';

const ManagerMenu = ({ managers, departments, setDepartment }) => {
    const items = managers.map((m) => ({
      key: m.email,
      label: `${m.firstName} ${m.lastName} (HC: ${m.headcount})`,

      children: departments
        .filter((d) => d.gdManagerId === m.id)
        .map(d => ({
        key: d.id,
        icon: React.createElement(AuditOutlined),
        label: `${d.title} (HC: ${d.headcount})`,
      }))
    }));

    items.unshift({
        key: 0,
        label: 'All departments'
    })

    return (
        <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{
              height: '100vh',
              borderRight: 0
            }}
            items={items}
            onClick={({ key }) => setDepartment(Number(key))}
    />
    );
};

export default ManagerMenu;