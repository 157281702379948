import React, { useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { saveEmployee } from '../features/EmployeeSlice';
import { useDispatch } from 'react-redux';
import { Typography } from 'antd';

const { Title } = Typography;

const EmployeesTable = ({ header, employees, locations, projects, roles, titles }) => {
  const dispatch = useDispatch();
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      width: 70,
      valueFormatter: (params) => {
        return `${parseInt(params.node.id) + 1}`;
      },
    },
    { headerName: 'Name', valueGetter: o => o.data.firstName + ' ' + o.data.lastName, },
    {
      field: 'location',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: titles.locations,
        valueListMaxHeight: 200,
      }
    },
    {
      field: 'project',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: titles.projects,
        valueListMaxHeight: 200,
      }
    },
    {
      field: 'squad',
      editable: true,
      cellEditor: 'agTextCellEditor',
    },
    {
      field: 'role',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
          values: titles.roles,
          valueListMaxHeight: 200,
      }
    },
    { headerName: 'GD Email', field: 'gdEmail', },
    {
      field: 'LDAP',
      editable: true,
      cellEditor: 'agTextCellEditor',
    },
    {
      field: 'clientEmail',
      editable: true,
      cellEditor: 'agTextCellEditor',
    },
    {
      field: 'phone',
      editable: true,
      cellEditor: 'agTextCellEditor',
    },
    { field: 'billability', },
    {
      field: 'contractType',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
          values: ['FTE', 'B2B' ],
      }
    },
    { field: 'onboardedDate', },
    { field: 'onboardingStatus' },
    { field: 'offboardedDate' },
]);

const onCellEditRequest = useCallback((event) => {
    const updatedEmployee = {
      ...event.data,
    }
    updatedEmployee[event.colDef.field] = event.value;
    switch (event.colDef.field){
      case 'location':
        updatedEmployee.locationId = locations.find(l => l.title === event.value).id;
        break;
      case 'project':
        updatedEmployee.projectId = projects.find(p => p.title === event.value).id;
        break;
      case 'role':
        updatedEmployee.roleId = roles.find(r => r.title === event.value).id;
        break;
    }

    dispatch(saveEmployee(updatedEmployee));
    return true;
  });
  return employees.length > 0 && <>
      <Title level={3}>{header}</Title>
      <AgGridReact
        domLayout='autoHeight'
        rowData={employees}
        columnDefs={columnDefs}
        onCellEditRequest={onCellEditRequest}
        readOnlyEdit={true}
        autoSizeStrategy={{
          type: 'fitCellContents',
          defaultMinWidth: 100,
        }}
      />
    </>;
};

export default EmployeesTable;