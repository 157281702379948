import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../index'
import { ProjectsApi } from '../api/Projects';
import { DepartmentsApi } from '../api/Departments';

export interface DepartmentState {
  departments: Array<any>;
  loading: boolean;
  errors: string;
}

const initialState: DepartmentState = {
  departments: [],
  loading: false,
  errors: '',
}

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setDepartments: (state, { payload }: PayloadAction<any[]>) => {
      state.departments = payload
    },
  },
})

export const getDepartments = (): AppThunk => {
    return async dispatch => {
      dispatch(setLoading(true))
      try {
        const managers = await DepartmentsApi.get();
  
        dispatch(setDepartments(managers))
        dispatch(setLoading(false))
      } catch (error) {
        if (typeof error === 'string'){
            dispatch(setErrors(error as string))
        } else {
            console.log(error);
            dispatch(setErrors('Unknown error'));
        }
        dispatch(setLoading(false))
      }
    }
  }

export const { setLoading, setErrors, setDepartments } = departmentSlice.actions

export default departmentSlice.reducer

export const departmentSelector = (state: { department: DepartmentState }) => state.department;
