import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../index'
import { LoginApi } from '../api';
import { User } from '../typing/Types';
import { UnauthorizedError } from '../api/Login';

export interface LoginState {
  user: User | null;
  isAuth: boolean;
  loading: boolean;
  errors: string[];
}

const initialState: LoginState = {
  user: null,
  isAuth: false,
  loading: false,
  errors: [],
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string[]>) => {
      state.errors = payload
    },

    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload
      state.isAuth = true;
    },
  },
})

export const login = (username: string, password: string): AppThunk => {
    return async dispatch => {
      dispatch(setLoading(true))
      try {
        const user = await LoginApi.login(username, password);
        
        dispatch(setLoading(false))
        dispatch(setUser(user))
      } catch (e: any) {
        if (e instanceof UnauthorizedError) {
          dispatch(setErrors([e.message as string]))
        } else {
          dispatch(setErrors(['unknown error']));
        }
        dispatch(setLoading(false))
      }
    }
  }

export const { setLoading, setErrors, setUser } = loginSlice.actions

export default loginSlice.reducer

export const loginSelector = (state: { login: LoginState }) =>
    state.login