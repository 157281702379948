import { baseURL } from "./ApiConfig";

export class UnauthorizedError {
    message;
    constructor (message) {
        this.message = message;
    }
}

export class LoginApi {
    static async login (username, password) {
        const request = new Request(`${baseURL}/login`, {
            method: 'POST',
            credentials: 'include',
            headers: new Headers({
                "Content-Type": "application/json",
            }),
            body: JSON.stringify({
                login: username,
                password,
            }),
        })
        try {
            const res = await fetch(request);
            if (res.status === 401) {
                const err = await res.json();
                throw new UnauthorizedError(err.error);
            }
            const raw = await res.json();
            return raw;
        } catch (e) {
            throw e;
        }
    }

    static async getCurrentUser() {
        const request = new Request(`${baseURL}/user`, {
            method: 'GET',
            credentials: 'include',
        })
        try {
            const res = await fetch(request);
            if (res.status === 401) {
                const err = await res.json();
                throw new UnauthorizedError(err.error);
            }
            const raw = await res.json();
            return raw;
        } catch (e) {
            throw new Error(e);
        }
    }
}
