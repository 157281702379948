export const teamRegApi = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}${process.env.REACT_APP_API_BASE_PATH}${process.env.REACT_APP_TEAM_REG_PREFIX}`
export const baseURL = `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}${process.env.REACT_APP_API_BASE_PATH}`;
export const teamRegEndpoints = {
    employees: '/employees',
    hierarchy: '/hierarchy',
    locations: '/locations',
    managers: '/managers',
    departments: '/departments',
    onboardings: '/onboardings',
    projects: '/projects',
    roles: '/roles',
    stats: '/stats',
}