import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../index'
import { ProjectsApi } from '../api/Projects';
import { ManagersApi } from '../api/Managers';

export interface ManagerState {
  managers: Array<any>;
  loading: boolean;
  errors: string;
}

const initialState: ManagerState = {
  managers: [],
  loading: false,
  errors: '',
}

const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setManagers: (state, { payload }: PayloadAction<any[]>) => {
      state.managers = payload
    },
  },
})

export const getManagers = (): AppThunk => {
    return async dispatch => {
      dispatch(setLoading(true))
      try {
        const managers = await ManagersApi.get();
  
        dispatch(setManagers(managers))
        dispatch(setLoading(false))
      } catch (error) {
        if (typeof error === 'string'){
            dispatch(setErrors(error as string))
        } else {
            console.log(error);
            dispatch(setErrors('Unknown error'));
        }
        dispatch(setLoading(false))
      }
    }
  }

export const { setLoading, setErrors, setManagers } = managerSlice.actions

export default managerSlice.reducer

export const managerSelector = (state: { manager: ManagerState }) => state.manager;
