import { snakeToCamel } from "./helpers";
import { teamRegApi, teamRegEndpoints } from "./ApiConfig";

export class DepartmentsApi {
  static async get () {
    try {
        const res = await fetch(`${teamRegApi}${teamRegEndpoints.departments}`, {
          method: 'GET',
          credentials: 'include',
        });
        const raw = await res.json();
        return this.normalizeRecords(raw);
    } catch (e) {
        throw new Error('Error while fetching departments');
    }
  }

  static normalizeRecords (data) {
    try {
      return snakeToCamel(data);
    } catch (e) {
      throw Error('Error during department records normalization');
    }
  }
}
