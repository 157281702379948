import { teamRegApi, teamRegEndpoints } from "./ApiConfig";

export class StatsApi {

    // GET /stats/DM
    static async getManagersStats (): Promise<Array<any>> {
        const res = await fetch(`${teamRegApi}${teamRegEndpoints.stats}/DM`, {
            method: 'GET',
            credentials: 'include',
          });
        const raw = await res.json();
        return normilizeManagerRecords(raw);
    }

    // GET /stats/country
    static async getCountriesStats (): Promise<Array<any>> {
        const res = await fetch(`${teamRegApi}${teamRegEndpoints.stats}/country`, {
            method: 'GET',
            credentials: 'include',
          });
        const raw = await res.json();
        return raw;
    }

    // GET /stats/billability
    static async getBillabilityStats (): Promise<Array<any>> {
        const res = await fetch(`${teamRegApi}${teamRegEndpoints.stats}/billability`, {
            method: 'GET',
            credentials: 'include',
          });
        const raw = await res.json();
        return raw;
    }
}

function normilizeManagerRecords(records: Array<any>) {
  return records.map(r => ({
    manager: r.GDManager,
    value: r.value,
  }))
}