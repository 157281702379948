import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../index'
import { ProjectsApi } from '../api/Projects';

export interface ProjectsState {
  projects: Array<any>;
  loading: boolean;
  errors: string;
}

const initialState: ProjectsState = {
  projects: [],
  loading: false,
  errors: '',
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload
    },

    setErrors: (state, { payload }: PayloadAction<string>) => {
      state.errors = payload
    },

    setProjects: (state, { payload }: PayloadAction<any[]>) => {
      state.projects = payload
    },
  },
})

export const getProjects = (): AppThunk => {
    return async dispatch => {
      dispatch(setLoading(true))
      try {
        const projects = await ProjectsApi.get();
  
        dispatch(setProjects(projects))
        dispatch(setLoading(false))
      } catch (error) {
        if (typeof error === 'string'){
            dispatch(setErrors(error as string))
        } else {
            console.log(error);
            dispatch(setErrors('Unknown error'));
        }
        dispatch(setLoading(false))
      }
    }
  }

export const { setLoading, setErrors, setProjects } = projectSlice.actions

export default projectSlice.reducer

export const projectSelector = (state: { project: ProjectsState }) => state.project;
